<template>
  <div :class="pulling ? 'random_game rerolling' : 'random_game'" ref="wrap">

       <div class="dream_id" >
          <img class="randombox" @click="grab_pull" alt="Randomizer" src="../assets/cb_tiny_1.png" >
          <router-link class="logobox" to="/"><img class="logo" alt="In Dreams" src="../assets/dream_id.png" ></router-link>
          <DreamHash :hash="dreamID"/>
      </div>
       <div class="dream_above">
          <div><a href="#" @click="grab_pull">Open the box</a> to unwrap a random game!</div>
          <TweetThis :v-if="pulling === false" class="share_dream"
              text="I dreamt about this game..."
              :url="'https://gamesweplayindreams.com/dream/'+ this.dreamID +'/'"
          />
       </div>
       <div v-if="artifacts && artifacts.length > 0" class="game_grid">
        <ArtifactOnShelfLazy v-for="(artifact, index) in artifacts.slice(0,6)"  :key="'art_'+index"
        :artifact="artifact" :class="'gg_' + (1+index)" :expand="false" :hideStack="true"
        :useThumbs="index == 0 ? false : true"/>
       </div>
    </div>
</template>
<style>
  .random_game .dream_id {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 940px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .share_dream {
    text-align: right;
  }

  .dream_above {
    max-width: 940px;
    padding: 0 1rem;
    margin: 0 auto;
    columns: 2;
  }

  .game_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    max-width: 940px;
    margin: 0 auto;
    padding: 1rem;
  }
  .dream_id .logo {
    max-height: 90px;
  }
  .dream_id .randombox {
    padding-right: 1em;
    height: 80px;
    cursor: grab !important;
    display: inline-block;
  }
  .rightside {
    margin-left: auto !important;
  }

.gg_1 { grid-area: 1 / 1 / 3 / 3; }
.gg_2 { grid-area: 3 / 1 / 4 / 2; }
.gg_3 { grid-area: 1 / 3 / 2 / 4; }
.gg_4 { grid-area: 2 / 3 / 3 / 4; }
.gg_5 { grid-area: 3 / 2 / 4 / 3; }
.gg_6 { grid-area: 3 / 3 / 4 / 4; }

  @media screen and (max-width: 940px) {
    .random_game .dream_id {
    /*single column layout*/
      display: block;
      height: auto;
      width: 100%;
      min-height: 60px;
      order: 1;
    }
    .randombox {
      display: inline;
    }

    .random_game .dream_id .logo {
      height: 60px;
      margin: 0 auto;
    }
    .random_game .dream_id .randombox {
      height: 60px;
    }
    .random_game {
      display: flex;
      flex-flow: column;
      align-items: stretch;
    }
    .game_grid {
      order: 2;
    }
    .dream_above {
      order: 3;
      padding-bottom: 1rem;
    }
  }

  @media screen and (max-width: 640px) {
    .game_grid {
      grid-column-gap: .5em;
      grid-row-gap: .5em;
    }
  }
</style>
<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import ArtifactOnShelfLazy from '@/components/ArtifactOnShelfLazy.vue' // @ is an alias to /src
import DreamHash from '@/components/DreamHash.vue' // @ is an alias to /src
import TweetThis from '@/components/TweetThis.vue' // @ is an alias to /src

export default defineComponent({
  name: 'RandomGame',
  props: ['id'],
  components: {
    ArtifactOnShelfLazy,
    TweetThis,
    DreamHash
  },
  computed: {
    dreamID () {
      var did = ''
      if (this.artifacts) {
        for (var i = 0; i < this.artifacts.length && i < 6; ++i) {
          did += this.dreamHash(i)
        }
      }
      return did
    }
  },
  methods: {
    dreamHash (index) {
      if (this.artifacts && index < this.artifacts.length) {
        var hashid = this.artifacts[index].id.toUpperCase()
        return (index > 0 ? '.' + hashid : hashid)
      }
      return Math.floor(Math.random() * 999)
    },
    fill_from_pulls () {
      if (this.pulls.length > 0) {
        const pullFrom = this.pulls.pop()
        this.qty = pullFrom.qty
        this.shelves = pullFrom.shelves
        this.promoted = pullFrom.promoted
        this.shuffled = pullFrom.shuffled
        this.artifacts = pullFrom.artifacts
        this.pulling = false
        this.$router.push({
          name: 'Dream',
          params: { dream_id: this.dreamID },
          replace: false
        })
      }
    },
    async reroll (url) {
      this.rolling = true
      try {
        const response = await axios.get(url)
        this.pulls = this.pulls.concat(response.data.pulls)
        if (this.pulling === true) {
          this.fill_from_pulls()
          this.pulling = false
        }
      } catch (e) {
        console.log('Failed to dream!')
        this.pulling = false
      }
      this.rolling = false
    },
    grab_pull () {
      // Don't pull again till done.
      if (this.pulling === true) {
        return
      }
      this.pulling = true
      // Cache more as needed.
      if (this.pulls.length < 5) {
        this.reroll('/api/v1/mixup/4/1/21/20/')
      }
      this.fill_from_pulls()
    }
  },
  data () {
    return {
      pulls: [],
      artifacts: [],
      promoted: {},
      shelves: [],
      qty: [],
      shuffled: [],
      rolling: false,
      pulling: false,
      prior_h: null,
      prior_w: null
    }
  },
  mounted () {
    this.prior_h = this.$refs.wrap.clientHeight
    this.prior_w = this.$refs.wrap.clientWidth
  },
  async created () {
    this.pulling = true
    if (this.id) {
      this.reroll('/api/v1/specific/' + this.id + '/')
    } else {
      this.reroll('/api/v1/mixup/4/1/21/20/')
    }
  }
})
</script>
