<template>
    <div class="dreamhash" >
      <h2>
          <img v-show="upperHash.length > 0 && this.isArtifact != true" class="hash" alt="#" src="../assets/dream_hash.png" >
           <span>
              {{this.upperHash}}
          </span>
      </h2>
      <h3 v-show="upperHash.length > 0" class="undreamt">
      {{this.isArtifact == true ? '(Artifact #'+hash+')' : '#'+hash}}
      </h3>
    </div>
</template>
<style>
  @font-face {
    font-family: "Dreamlike";
    src: url('./../assets/Dreamlike-Regular.otf');
  }
  .dreamhash {
    display: flex;
    flex-flow: column;
    width: 100%;
  }
  .dreamhash h2, .dreamhash h3 {
    padding: 0; margin: 0;
    text-align: right;
    white-space: nowrap;
    line-height: 1;
  }
  .dreamhash .undreamt {
    font-style: italic;
    display: inline-block;
    vertical-align: text-bottom;
    font-weight: 200;
    padding: 0; margin: 0;
    font-size: 16px;
  }

  .dreamhash .hash {
    height: auto;
    width: 5%;
    vertical-align: text-bottom;
  }
  .dreamhash h2 span {
    display: inline;
    font-size: 40px;
    font-weight: 500;
    font-family: Dreamlike;
    background-image: linear-gradient(
      to top,
      #462523 0,
      #cb9b51 22%,
      #f6e27a 45%,
      #cb9b51 78%,
      #462523 100%
    );
    padding: 0 !important;
    background-clip:text;
    color:transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #cb9b51;
  }
  @media screen and (max-width: 940px) {
    .dreamhash h2 span, .dreamhash .undreamt {
        vertical-align: text-bottom;
    }
    .dreamhash .hash {
      height: 40px;
      width: auto;
    }
    .dreamhash h2 span {
      padding: 0;
      font-size: 32px;
      font-weight: 400;
      -webkit-text-stroke-width: .5px;
    }
  }
  @media screen and (max-width: 640px) {
    .dreamhash h2 span {
      font-size: 28px;
    }
    .dreamhash .hash {
      height: 30px;
    }
  }

</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DreamHash',
  props: ['hash', 'isArtifact'],
  computed: {
    upperHash () {
      if (this.hash) {
        return this.hash.toUpperCase()
      }
      return ''
    }
  }
})
</script>
