
import { defineComponent } from 'vue'
import RandomGame from '@/components/RandomGame.vue' // @ is an alias to /src

export default defineComponent({
  name: 'Randomizer',
  props: ['dream_id'],
  components: {
    RandomGame
  },
  data () {
    return {
    }
  }
})
