<template>
  <div class="about">
    <p class="text-center"><img style="max-height: 450px" src="../assets/logo_big.png" alt="Games We Play In Dreams"></p>
    <div  class="join_button">
      <a href="https://itch.io/jam/games-we-play-in-dreams">Join the fun on itch.io.</a>
    </div>
    <h2>AI-generated art is now widely available.</h2>
    <p>What we do with this power is going to have a huge impact on how we define art, creativity, and value. I'm excited for a future where anyone can bring their imagination to life, where it can enhance anyone's creative process; I'm worried about a world where that power, built from the labor of unpaid artists, is turned against us in the name of bland content and big profits.</p>
    <p>I think the best way to work with this new technology is collaboratively, human and machine, using AI's alien perspective to spark creativity and inspire us to make new things. <strong>AI generated stuff should be the starting point, not the final product.</strong> This jam is gonna give us experience doing that.<br></p>
    <p class="text-center"><img src="../assets/layoutstrip_1.png"><br></p>
    <h2 id="#sowhat">So what is this jam?</h2>
    <p>For this jam, I've asked the AI what pages from various imaginary tabletop roleplaying games might look like, given a theme or certain art styles. The results are filled with the appearance of coherence without actually supplying it, dreamlike in the way they demand the viewer to fill in the gaps, interpreting meanings where none exist.</p>
    <p>I'm asking you to look at these images, get inspired, and turn what you see into a tabletop roleplaying game. <br></p>
    <p class="text-center"><img src="../assets/layoutstrip_2.png"></p>
    <h2 id="#rules">What are the rules?</h2>
    <h3>Use the images as inspiration.</h3>
    <p>The whole point of this jam is to get those ideas flowing based on AI generated images, turning incoherent nonsense into art.</p>
    <ul>
    <li><p><strong>Get inspired.</strong><br>I'll be uploading a resource pack with AI generated art once the jam starts.</p><p>Draw on those images for themes, content, layout, rules... whatever you imagine in them. But your idea has to start with an image.</p></li>
    <li><p><strong>Share your inspiration.</strong><br>When you upload a finished game, include a folder called "inspiration" with the pieces that inspired you, so we can all enjoy seeing how different images are developed. (If you use AI to generate your own page layout inspiration, upload those images too).</p></li>
    </ul>
    <h3>Make it one piece of paper.</h3>
    <ul>
    <li><p><strong>Any size of paper is fine.</strong><br>Poster sized or postage stamp, your choice.</p></li>
    <li><p><strong>Print on only one side.</strong><br>Single-sided is nice because it's all right there in front of you. If you need more area, make the paper bigger.</p></li>
    <li><p><strong>It's gotta have style, not just text.</strong><br>Put your game together any way you can. If you're comfortable doing layout, do that! You can use one of the templates I've provided or make your own.</p><p><em>Not feeling like layout?</em><br>Slap some images and font colors into a google doc. Put the game together as a printable web-page. Edit a prompt image in MSPaint. Make a collage out of cut up magazines and then take a picture of it. Heck, if you're allergic to everything but a text editor, you could try some ASCII art for lines and columns. <strong>Anything goes as long as it's got style!</strong></p></li>
    </ul>
    <div style="display: flex;">
    <div class="about_box">
    <h3>One month, no prizes, no limits.</h3>
    <ul><li><p><strong>The jam runs for an entire month.</strong><br>Take as much or as little of that time as you need.</p></li>
    <li><p><strong>There's no reward for finishing...</strong><br> ...except that you've made a really cool game, and we all get to play it.</p></li>
    <li><p><strong>Make a game.</strong><br>Submit it when you're happy with it. If you get inspired again and still have time, submit as many games as you want.</p></li>
    </ul>
    <h2 id="#inspire">Where do I get the inspiration images?</h2>
    <h3>You're at the right place...</h3>
    <p><router-link to="/randomizer">Open the box</router-link> for a randomized prompt set, or <router-link to="/">browse the images</router-link> from the home page.</p>
    </div>
    <RandomizerButton />
    </div>
  </div>
</template>
<style>
  .about .rand_button {
    top: 0;
  }
  .text-center {
    text-align: center;
  }
  .about {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 1em 1rem 1rem;
  }
  .about img {
    max-width: 100%;
  }
  .join_button a {
    transition: all .2s;
    display: block;
    font-weight: 600;
    text-align: center;
    padding: .75rem 1.25rem;
    margin: 0 auto;
    max-height: 50px;
    border-radius: 4px;
    box-shadow: 0px 4px 3px rgba(0,0,0,.25);
    max-width: 50%;
    text-decoration: none;
    color: #FFF;
    border: 1px solid #2A61B6;
    background: linear-gradient(0deg,#2A2092,#2A2092);
    text-shadow: 0 1px 0px #002092;
  }

  .join_button a:hover {
    border: 1px solid #2AA4B6;
    text-shadow: 0 1px 0px #002092;
    background: linear-gradient(0deg,#2A4B92,#2A2092);
  }
</style>
<script>
import { defineComponent } from 'vue'
import RandomizerButton from '@/components/RandomizerButton.vue'

export default defineComponent({
  name: 'About',
  components: {
    RandomizerButton
  },
  beforeRouteEnter () {
    window.scrollTo(0, 0)
  }
})
</script>
